/**
 * Social Media Image Components
 */

import React from 'react'
import Image from '../image'

export class ImageSocialFacebook extends React.Component {
  render() {
    return (
      <div
        className={`social-icon`}
        style={{
          height: `32px`,
          width: `32px`,
        }}>
        <Image alt="Facebook Circle Icon" filename="social/facebook-circle.png" />
      </div>
    )
  }
}

export class ImageSocialInstagram extends React.Component {
  render() {
    return (
      <div
        className={`social-icon`}
        style={{
          height: `32px`,
          width: `32px`,
        }}>
        <Image alt="Instagram Circle Icon" filename="social/instagram-circle.png" />
      </div>
    )
  }
}

export class ImageSocialPinterest extends React.Component {
  render() {
    return (
      <div
        className={`social-icon`}
        style={{
          height: `32px`,
          width: `32px`,
        }}>
        <Image alt="Pinterest Circle Icon" filename="social/pinterest-circle.png" />
      </div>
    )
  }
}

export class ImageSocialYelp extends React.Component {
  render() {
    return (
      <div
        className={`social-icon`}
        style={{
          height: `32px`,
          width: `32px`,
        }}>
        <Image alt="Yelp Circle Icon" filename="social/yelp-circle.png" />
      </div>
    )
  }
}
