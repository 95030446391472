/**
 * Complete Modal
 * 
 * Shows when a section is completed, and asks to go back to the main overview page.
 */

import React from 'react'
import { navigate } from 'gatsby'
import Modal from 'react-bootstrap/Modal'

export default class CompleteModal extends React.Component {
  render() {
    const { modalShow, modalDismissed, modalClose } = this.props
    return (
      <Modal show={modalShow && !modalDismissed} onHide={modalClose} centered aria-labelledby="complete-modal-title">
        <Modal.Header closeButton>
          <Modal.Title id="complete-modal-title">Finished?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Looks like you've completed everything here! Would you like to return to the overview page?</p>

          <button className={`btn btn-block btn-danger text-uppercase mt-4`} onClick={() => navigate(`/`)}>
            Yes!
          </button>
          <button className={`btn btn-block btn-tiny mt-4`} onClick={modalClose}>
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    )
  }
}

CompleteModal.defaultProps = {
  modalShow: false,
  modalDismissed: false,
  modalClose: () => {},
}
