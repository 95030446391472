/**
 * Question End Component
 * 
 * Shown in a popover once an answer was submitted to a question,
 * letting the user know if they got the answer correct or incorrect, and
 * how many points they earned.
 * Shows different illustrations and text based on the state of the question.
 */

import React from 'react'
import CountUp from 'react-countup'
import { CrownIllustration, GoggleDogIllustration, SkullIllustration } from '../images/illustrations'

class QuestionEnd extends React.Component {
  render() {
    let end_text = this.props.partialSuccessText
    let IllustrationElem = GoggleDogIllustration
    if (this.props.points == 0) {
      end_text = this.props.failText
      IllustrationElem = SkullIllustration
    } else if (this.props.points == this.props.possiblePoints) {
      end_text = this.props.successText
      IllustrationElem = CrownIllustration
    }
    return (
      <div className="question-end py-5 px-4">
        <div className="text-center">
          {this.props.showAnswer && <p className={`answer`}>Answer: {this.props.answer}</p>}
          <div className="row no-gutters align-items-center justify-content-center mb-3 points-container">
            <div className="col text-right">
              You
              <br />
              earned
            </div>
            <div className="col points px-2">
              <CountUp end={this.props.points} delay={1} /> / {this.props.possiblePoints}
            </div>
            <div className="col text-left">
              possible
              <br />
              points!
            </div>
          </div>
          <p className={`end-text`}>{end_text}</p>
          <div className="d-flex justify-content-center">
            <div className={`icon-container`}>
              <IllustrationElem />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

QuestionEnd.defaultProps = {
  successText: `Good job smarty pants!`,
  partialSuccessText: `Pretty good!`,
  failText: `Better luck next time!`,
}

export default QuestionEnd
