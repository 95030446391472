/**
 * Thank You Card
 * 
 * Shows thank you message and Watson social links after the hunt is over.
 * 
 * Shown on the Finish Page.
 */

import React from 'react'
import { getSiteData } from '../utils/site'
import {
  ImageSocialFacebook,
  ImageSocialInstagram,
  ImageSocialPinterest,
  ImageSocialYelp,
} from './images/socials'
import { isPermanentCode } from '../utils/team-data'
import { getCurrentHuntData } from '../utils/hunt-data'

export default class CardThanksForPlaying extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      huntData: getCurrentHuntData(),
      completed_text: ``,
      social_text: `<p>For the latest fun visit our website at <a href="https://watsonadventures.com" target="_blank" rel="noopener">watsonadventures.com</a> or follow us below:</p>`,
      facebook_url: ``,
      instagram_url: ``,
      pinterest_url: ``,
      yelp_url: ``,
      isPermanent: isPermanentCode(),
    }
  }

  componentDidMount() {
    let state_data = {}
    const huntData = this.state.huntData
    getSiteData().then(data => {
      if (data.completed_text !== null) {
        state_data.completion_text = data.completed_text
      }
      if (data.social_text !== null) {
        state_data.social_text = data.social_text
      }
      if (data.facebook_url !== null) {
        state_data.facebook_url = data.facebook_url
      }
      if (data.instagram_url !== null) {
        state_data.instagram_url = data.instagram_url
      }
      if (data.pinterest_url !== null) {
        state_data.pinterest_url = data.pinterest_url
      }
      if (data.yelp_url !== null) {
        state_data.yelp_url = data.yelp_url
      }

      if (huntData.hunt !== null && huntData.hunt.completion_text !== null) {
        state_data.completion_text = huntData.hunt.completion_text
      }
      this.setState(state_data)
    })
  }

  render() {
    const data = this.state.huntData
    const enable_gallery = data.hunt !== undefined ? data.hunt.enable_gallery : false

    return (
      <>
        <div className="card full-height">
          <div className="card-header text-center">
            <h3>Thanks for playing!</h3>
          </div>
          <div className="card-body text-center justify-content-between">
            {data.hunt !== undefined && (
              <div className="completion-text" dangerouslySetInnerHTML={{ __html: this.state.completion_text }} />
            )}
            {!this.state.isPermanent && enable_gallery && (
              <div>
                <p>To access your hunt photos, check your email or visit the link below.</p>
                <a
                  className="btn btn-block btn-success btn-arrow mb-3"
                  href={`/gallery/?code=${data.hunt.gallery_code}`}>
                  Hunt Photos
                </a>
              </div>
            )}
            <div>
              <div dangerouslySetInnerHTML={{ __html: this.state.social_text }} />
              <a
                href="https://watsonadventures.com"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-block btn-success btn-arrow mb-4">
                Visit Our Site
              </a>
              <div className="social-container d-flex justify-content-center">
                {this.state.facebook_url && (
                  <a href={this.state.facebook_url} target="_blank" rel="noopener noreferrer">
                    <ImageSocialFacebook />
                  </a>
                )}
                {this.state.instagram_url && (
                  <a href={this.state.instagram_url} target="_blank" rel="noopener noreferrer">
                    <ImageSocialInstagram />
                  </a>
                )}
                {this.state.pinterest_url && (
                  <a href={this.state.pinterest_url} target="_blank" rel="noopener noreferrer">
                    <ImageSocialPinterest />
                  </a>
                )}
                {this.state.yelp_url && (
                  <a href={this.state.yelp_url} target="_blank" rel="noopener noreferrer">
                    <ImageSocialYelp />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
