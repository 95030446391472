/**
 * Finish Card
 * 
 * Asks if the team is finished with the game. Accepts the stop word.
 * 
 * Used on the Finish Page, and the last slide in the Questions slider
 */

import React from 'react'
import { getCurrentTeamData, teamUpdateEnd, teamUpdateName, isTeamCaptain, isPermanentCode } from '../utils/team-data'

import StopButton from './stop'
import FloatingInput from './floating-input'
import { getSiteData } from '../utils/site'
import { PlaneIllustration } from './images/illustrations'
import AppContext from '../utils/context'
import { getCurrentHuntData } from '../utils/hunt-data'
import { getStaticUrl } from '../utils/helpers'

export default class FinishBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: getCurrentHuntData(),
      play_sounds: true,
      alertShow: false,
      needTeamName: false,
      teamName: ``,
      alertText: ``,
      finish_headline: `Are You Finished?`,
      finish_text: `<p>If so, go to the finish line and find a Watson Adventures staffer to get the "stop word."</p>`,
      //height: 0,
      errors: ``,
    }
    this.stopTimer = this.stopTimer.bind(this)
    this.playCompleteAudio = this.playCompleteAudio.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  componentDidMount() {
    if (!isTeamCaptain()) {
      // only the team captain can visit this page
      //navigate(`/`)
    }
    //this.setState({ height: window.innerHeight })
    const curTeamData = getCurrentTeamData()
    let data = this.state.data
    let state_data = {}
    getSiteData().then(data => {
      if (data.finish_headline !== null) {
        state_data.finish_headline = data.finish_headline
      }
      if (data.finish_text !== null) {
        state_data.finish_text = data.finish_text
      }
    })
    if (
      data.hunt !== null &&
      data.hunt.finish_text !== null &&
      data.hunt.finish_text != undefined &&
      data.hunt.finish_text != ``
    ) {
      state_data.finish_text = data.hunt.finish_text
    }

    let needTeamName = curTeamData.team_name == null || curTeamData.team_name == ``
    let finish_sound = data.hunt.sound_complete ? data.hunt.sound_complete.file : getStaticUrl(`/sounds/fanfare.mp3`)
    let play_sounds = data.hunt.play_sounds == 1
    state_data.play_sounds = play_sounds
    state_data.needTeamName = needTeamName
    state_data.is_permanent = isPermanentCode()
    this.finish_audio = new Audio(finish_sound)
    this.setState(state_data)
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  playCompleteAudio() {
    if (this.state.play_sounds) {
      this.finish_audio.play()
    }
  }

  stopTimer(stopword) {
    const { afterFinish } = this.props
    // stop has been submitted, check if it's correct
    let data = this.state.data
    this.setState({ alertShow: false, errors: `` })
    // check if the team name needs to be submitted
    if (!this.state.is_permanent && this.state.needTeamName) {
      if (this.state.teamName == ``) {
        this.setState({ errors: `Team name required!` })
        return
      } else {
        // submit the team name
        teamUpdateName(this.state.teamName).then(() => {
          //console.log('team name updated');
          if (data.hunt !== undefined && data.hunt.stop_word.trim().toLowerCase() == stopword.trim().toLowerCase()) {
            // matched, stop the hunt
            this.playCompleteAudio()
            this.setLoading(true)
            teamUpdateEnd().then(() => {
              afterFinish()
              this.setLoading(false)
            })
          } else {
            this.setState({ alertShow: true, alertText: `Incorrect stop word!` })
          }
        })
      }
    } else {
      if (data.hunt !== undefined && data.hunt.stop_word.trim().toLowerCase() == stopword.trim().toLowerCase()) {
        // matched, stop the hunt
        this.playCompleteAudio()
        this.setLoading(true)
        teamUpdateEnd().then(() => {
          afterFinish()
          this.setLoading(false)
        })
      } else {
        this.setState({ alertShow: true, alertText: `Incorrect stop word!` })
      }
    }
  }

  render() {
    let card_finish_body = (
      <>
        <PlaneIllustration />
      </>
    )
    if (isTeamCaptain()) {
      card_finish_body = (
        <>
          <div className="mt-auto pb-3">
            {!this.state.is_permanent && this.state.needTeamName && (
              <FloatingInput
                name={`teamName`}
                placeholder={`Enter Team Name`}
                onChange={this.handleUpdate}
                value={this.state.teamName}
                error={this.state.errors}
              />
            )}
            <StopButton onSubmit={this.stopTimer} />
            {this.state.alertShow && <p className="incorrect text-danger">{this.state.alertText}</p>}
          </div>
        </>
      )
    }
    return (
      <AppContext.Consumer>
        {({ setLoading }) => {
          this.setLoading = setLoading
          return (
            <div className={`mx-4`}>
              <div className={`card full-height`}>
                <div className={`card-header text-center`}>
                  <h3>{this.state.finish_headline}</h3>
                </div>
                <div className={`card-body`}>
                  <div className="card-text" dangerouslySetInnerHTML={{ __html: this.state.finish_text }} />
                  {card_finish_body}
                </div>
              </div>
            </div>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

FinishBlock.defaultProps = {
  afterFinish: () => {},
}
