/**
 * Score Card
 * 
 * Displays the team's final score, with animations
 * 
 * Shown on the Finish Page
 */

import React from 'react'
import CountUp from 'react-countup'
import { getTeamTotalScore } from '../utils/team-data'
import SvgFirework from './svg/firework'
import SvgFireworksCenter from './svg/fireworks-center'

export default class CardScore extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
    }
  }

  componentDidMount() {
    this.setState({ height: window.innerHeight })
  }

  render() {
    const points = getTeamTotalScore()

    return (
      <>
        <div className="card full-height score-card">
          <div className="card-header text-center">
            <h3>You did it!</h3>
          </div>
          <div className="card-body text-center text-uppercase">
            <SvgFirework className={`firework firework-1`} />
            <p className="my-3">
              <span className={`d-block`}>You</span>
              <span>
                earned{` `}
                <strong className="points text-huge d-block my-3">
                  <SvgFireworksCenter className={`fireworks-center`} />
                  <CountUp end={points} />
                </strong>
                {` `}
                total
              </span>
              <span className={`d-block`}>Points!</span>
            </p>
            <SvgFirework className={`firework firework-2`} />
          </div>
          <div className="card-footer bg-transparent border-0">
            <button
              className={`btn btn-block btn-primary btn-arrow text-uppercase mt-auto`}
              onClick={this.props.nextFunc}>
              Next
            </button>
          </div>
        </div>
      </>
    )
  }
}
