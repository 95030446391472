/**
 * Stop Button Component
 * 
 * Handles the stop word submission on the finish page.
 */

import React from 'react'
import { isTeamCaptain } from '../utils/team-data'
import FloatingInput from './floating-input'

export default class StopButton extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.state = {
      stopword: ``,
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    this.props.onSubmit(this.state.stopword)
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render() {
    const isCaptain = isTeamCaptain()
    return (
      <div>
        {isCaptain && (
          <form
            className="form-block"
            method="post"
            onSubmit={event => {
              this.handleSubmit(event)
            }}>
            <FloatingInput
              name={`stopword`}
              placeholder={`Enter the Stop Word`}
              onChange={this.handleUpdate}
              value={this.state.stopword}
            />

            <button className={`btn btn-primary btn-block btn-arrow`} type="submit">
              Finished!
            </button>
          </form>
        )}
      </div>
    )
  }
}
